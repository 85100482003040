<template>
	<div class="page">
		<top />
		<div style="width: 100%;height: 55px;"></div>
		<designer />
		<div class="home">
			<div  @click="go('My')">我的主页</div>
			<div><</div>
			<div style="color: #E66617;">我的订单</div>
		</div>
		<div class="orderList">
			<div class="orderList-content borderbottom">
				<!-- <el-select v-model="value" style="width:40%;" placeholder="选择分区">
				    <el-option
				      v-for="item in options"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value">
				    </el-option>
				  </el-select> -->
				<div class="orderList-list information">
					<div>作品信息</div>
					<div>价格</div>
					<div>操作</div>
				</div>
			</div>
			<div class="orderList-content">
				<div class="orderList-list content" v-for="(item,index) in tableData">
					<div>
						<img :src="imageUrl(item.product_litpic)" alt="">
						<div style="margin-top: 10px;">编号<span style="margin-left: 5px;">{{ item.product_id }}</span></div>
					</div>
					<div class="money">￥{{ item.order_amount }}</div>
					<div class="confirm" @click="confirmOrderDo(item.order_id)" v-if="item.order_status == 1">确认收货</div>
					<div class="confirm1" @click="goD('Details',item.product_id)" v-else>去下载</div>
				</div>
			</div>
			<div class="page">
				<el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="size"
				layout="total, prev, pager, next ,jumper" :pager-count="5" :total="count">
				</el-pagination>
			</div>
		</div>
		<info />
		<div style="width: 100%;height: 60px;background-color: #F6F6F6;"></div>
		<foot />
		</div>
</template>

<script>
	import top from "../../components/common/header.vue";
	import foot from "../../components/common/footer.vue";
	import info from "../../components/common/qrCode.vue";
	import designer from "../../components/common/designer.vue";
	import {
		memberTopData,
		myOrder,
		confirmOrder
	} from "../../request/api";
	export default {
		components: {
			top,
			foot,
			designer,
			info
		},
		data() {
			return {
				value: '',
				tableData: [],
				count: 1,
				size: 6,
				page: 1,
			};
		},

		created() {
			this.getMyOrder();
		},
		methods: {
			getMyOrder() {
				myOrder({
					page: this.page,
					size: this.size,
				}).then((res) => {
					console.log(res);
					console.log(111111111)
					this.tableData = res.data.order;
					this.count = res.data.count;
				});
			},
			go(url) {
				this.$router.push({
					name: url,
				});
			},
			goD(url,aid) {
				this.$router.push({
					name: url,
					query:{
					  aid:aid
					}
				});
			},
			//确认收货
			confirmOrderDo(order_id) {
				confirmOrder({
					order_id: order_id,
				}).then((res) => {
			
			
					if (res.code == 200) {
			
						this.$message({
							message: res.msg,
							type: "success",
						});
						this.getMyOrder()
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.getMyOrder();
			},
		},
	};
</script>

<style lang="less" scoped>
	.page {
		background: #F6F6F6;
		.home {
			padding: 0 20px;
			display: flex;
			align-items: center;
			font-size: 13px;
		
			div {
				margin-right: 7px;
			}
		}
		.orderList {
			margin-top: 15px;
			padding: 0 10px;
			.orderList-content {
				padding: 18px 12px;
				
				font-size: 13px;
				background-color: #FFFFFF;
				color: #000000;
			}
			.borderbottom {
				border-bottom: 1px solid #ECECEC;
			}
			.page {
				position: relative;
				left: -15%;
				transform: scale(0.7);
			}
			.orderList-list {
				display: flex;
				align-items: center;
				justify-content: space-between;
				img {
					width: 100px;
					height: 100px;
					object-fit: cover;
				}
				.money {
					position: absolute;
					left: 50%;
					transform: translateX(-50%)
				}
			}
			.information {
				margin-top: 16px;
				padding-right: 20px;
			}
			.content {
				margin-bottom: 30px;
				color: #08243C;
				.confirm {
					border-radius: 12px;
					padding: 6px 9px;
					font-size: 12px;
					border: 1px #08243C solid;
				}
				.confirm1 {
					border-radius: 12px;
					color: #C92323;
					padding: 6px 19px;
					font-size: 12px;
					border: 1px #C92323 solid;
				}
			}
		}

		
	}
</style>
